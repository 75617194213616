// fonts
$primary-font: sans-serif;

// font weights
$light: 400;
$medium: 600;
$bold: 800;

// font sizes
$title: 36px;
$subtitle: 24px;
$description: 12px;
$subdescription: 9px;

// linespacing

// colors
$color-background-default: #2f4f4f;
$color-background-secondary: #fff;
$color-text-title: #000fff;
$color-text-subtitle: chocolate;
$color-text-highlight: greenyellow;
$color-text-dark: #222;
$color-text-light: #fff;
// $color-primary: red;
// $color-secondary: red;
// $color-link-primary: red;
// $color-link-secondary: red;

// margins
$margin-page: 40px;


