h3 {
  color: greenyellow;
}

main {
  padding: 40px;
}

a:link {
  color: #B0C4DE;
}

a:visited {
  color: #B0C4DE;
}

a:hover {
  color: magenta;
}

.hover-nick:hover:after {
  content: '  aka: Taka';
}

.contact-info {
  margin: 10px 0;
}


@media (max-width: 500px) {
  main {
    padding: 20px;
  }
  header {
    padding: 30px 20px;
  }
}
