.footer {
  padding: 0 40px 40px 40px;

  @media (max-width: 500px) {
    footer {
      padding: 0 20px 20px 20px;
    }
  }
}

