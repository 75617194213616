@import '../sass/variables';

.header {
  color: $color-text-dark;
  background-color: $color-background-secondary;
  padding: $margin-page;
  width: 100%;

  &--title {
      margin: 10px 0;
      padding: 0;
  }

  h3 {
    color: $color-text-subtitle;
  }

  .email {
    &:link, &:visited {
      color: $color-text-title;
    }

    &:hover {
      color: $color-text-highlight;
    }
  }
}
